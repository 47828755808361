import { useState } from 'react'; 
import { Outlet, useLocation,  useNavigate, Navigate } from "react-router-dom";

import MainHeader from "../components/shared/MainHeader";
import MainNavigation from "../components/shared/MainNavigation";
import Footer from "../components/shared/Footer";
import Login from "../pages/Login";
import useToken from '../useToken';
import { verifyToken } from '../dataAccess/getData'; 
import * as constDefs from '../constants/constants';

function Root() {
    //const [userName, setUserName] = useState(sessionStorage.getItem(constDefs.authNameKey));

    const currentLocation = useLocation();
    //console.log("currentLocation is: " + currentLocation.pathname);

    const { token, setToken } = useToken(); 

    if (!token) {
        return <Login setToken={setToken} />
    }

    //setUserName(sessionStorage.getItem(constDefs.authNameKey));
    //console.log("userName is: " + userName);

    if (!sessionStorage.getItem(constDefs.authIdKey)) {
        let tokenObj = {"token": token};
        verifyToken(tokenObj)
        .then((returnVal) => {
                if (returnVal.tokenExists === 'yes') {
                  if (returnVal.id) {
                      sessionStorage.setItem(constDefs.authIdKey, returnVal.id);
                      sessionStorage.setItem(constDefs.authNameKey, returnVal.name);
                      //setUserName(sessionStorage.getItem(constDefs.authNameKey));
                  } else {
                      sessionStorage.removeItem(constDefs.authIdKey);
                      sessionStorage.removeItem(constDefs.authNameKey);
                      return <Login setToken={setToken} />
                  }
                } else {
                  sessionStorage.removeItem(constDefs.authIdKey);
                  sessionStorage.removeItem(constDefs.authNameKey);
                  return <Login setToken={setToken} />
                }
            })
        .catch(e => {
            let errMsg = "ERROR: verifyToken: " + e;
        });
    } 

    let rootContent = "";
    rootContent = (
        <>
            <header>
                <MainHeader />
            </header>
            <div>
                <MainNavigation />
            </div>
                <Outlet />
            <div>
                <Footer />
            </div>
        </>
    );

    return (
        <div>
            {rootContent}
        </div>
    ); 
}

export default Root;
