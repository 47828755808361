import * as constDefs from '../constants/constants';
import getPlayersAvailabilityList from './getDataTest';
import { compare } from '../shared';

/******************************************
 * getMatches
 * @returns promise of matches data
 *****************************************/
export async function getMatches() {
    const responseData = await fetch(constDefs.getMatchesUrl)
        .then(async response => {
            const data = await response.json();
            //Check for error response
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            //Return data if no error
            //return data.sort(compare);
            return data;
        })
        .catch(error => {
            let errMsg = "ERROR: " + error;
            console.log(errMsg);
            return Promise.reject(errMsg);
        });

    return responseData;
}

/************************************************
 * getPlayersAvailability
 * @returns promise of players availability data
 ************************************************/
export async function getPlayersAvailability() {
    const responseData = await fetch(constDefs.getPlayersUrl)
    .then(async response => {
        const data = await response.json();
        // Check for error response
        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // Return data if no error
        return data.Items; 
    })
    .catch(error => {
        let errMsg = "ERROR: " + error;
        console.log(errMsg);
        //onError(errMsg);
        return Promise.reject(errMsg);
    });

    return responseData;
}

/************************************************
 * updatePlayers
 * @returns promise of success or failure 
 ************************************************/
export function updatePlayers(players, onError) {
    const playersData = JSON.stringify(players);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: playersData 
    };
    fetch(constDefs.postPlayersUrl, requestOptions) 
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json(); 

            //check for error response
            if (!response.ok) {
                //get error message from body or default to response status
                const error = (data && data.message) || response.status;
                //updateErrorHandling(error);
                return Promise.reject(error);
            }

            return response;
        })
        .catch(error => {
            let errMsg = "ERROR: updatePlayers: " + error;
            console.error(errMsg);
            //throw new Error('Fetching matches failed' + error);
            //return ("Error: " + error);
            onError(errMsg);
            //return Promise.reject(error);
        })
}

/************************************************
 * getPlayers
 * @returns promise of players data
 ************************************************/
export async function getPlayers(onError) {
    const responseData = await fetch(constDefs.getPlayersUrl)
    .then(async response => {
        const data = await response.json();
        // Check for error response
        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // Return data if no error
        return data.Items; 
    })
    .catch(error => {
        let errMsg = "ERROR: getPlayers: " + error;
        console.log(errMsg);
        //onError(errMsg);
        //return Promise.reject(errMsg);
        onError(errMsg);
    });

    return responseData;
}

/************************************************
 * updateMatches
 * @returns promise of success or failure 
 ************************************************/
export function updateMatches(matches, onError) {
    const matchesData = JSON.stringify(matches);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: matchesData 
    };
    fetch(constDefs.postMatchesUrl, requestOptions) 
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json(); 

            //check for error response
            if (!response.ok) {
                //get error message from body or default to response status
                const error = (data && data.message) || response.status;
                //updateErrorHandling(error);
                return Promise.reject(error);
            }

            return response;
        })
        .catch(error => {
            let errMsg = "ERROR: updateMatches: " + error;
            console.error(errMsg);
            //throw new Error('Fetching matches failed' + error);
            //return ("Error: " + error);
            //onError(errMsg);
            return Promise.reject(error);
        })

}

/************************************************
 * getPermissionLevel
 * @returns promise of permission level 
 ************************************************/
export async function getPermissionLevel(keyPassVal, onError) {
    const keyPassValData = JSON.stringify(keyPassVal);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: keyPassValData
    };
    const responseData = await fetch(constDefs.getPermissionLevelUrl, requestOptions) 
            .then(async response => {
                const data = await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    //updateErrorHandling(error);
                    return Promise.reject(error);
                }
                // Return data if no error
                return data;
            })
            .catch(error => {
                let errMsg = "ERROR: updateMatches: " + error;
                console.error(errMsg);
                return Promise.reject(error);
            })
    return responseData;
}

/************************************************
 * postToGroupMe
 * @returns promise of success or failure 
 ************************************************/
export async function postToGroupMe(matchObj) {
    const match = JSON.stringify(matchObj);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: match 
    };
    return await fetch(constDefs.postGroupMeMsg, requestOptions) 
        .then(response => {
            return response.text(); 
        })
        .then((data) => {
            Promise.resolve(data ? JSON.parse(data) : {})
        })
        .catch((error) => {
            let errMsg = "ERROR: postingToGroupMe: " + error;
            console.error(errMsg);
            Promise.reject(errMsg);
        })
}

/************************************************
 * sendPhoneNumber
 * @returns promise of phone number exists data 
 ************************************************/
export async function sendPhoneNumber(phoneNumVal, onError) {
    const phoneNumValData = JSON.stringify(phoneNumVal);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: phoneNumValData 
    };
    const responseData = await fetch(constDefs.sendPhoneNumberUrl, requestOptions) 
            .then(async response => {
                const data = await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    const error = (data && data.message) || response;
                    //updateErrorHandling(error);
                    //return Promise.reject(error);
                    return error;
                }
                // Return data if no error
                return data;
            })
            .catch(error => {
                let errMsg = "ERROR: sendPhoneNumber: " + error;
                console.error(errMsg);
                return Promise.reject(error);
            })
    return responseData;
}

/************************************************
 * verifyOTP
 * @returns promise of OTP validation data 
 ************************************************/
export async function verifyOTP(otpVal) {
    const otpValData = JSON.stringify(otpVal);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: otpValData 
    };
    const responseData = await fetch(constDefs.senOTPUrl, requestOptions) 
            .then(async response => {
                const data = await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    const error = (data && data.message) || response;
                    //updateErrorHandling(error);
                    //return Promise.reject(error);
                    return error;
                }
                // Return data if no error
                return data;
            })
            .catch(error => {
                let errMsg = "ERROR: sendOTP: " + error;
                console.error(errMsg);
                return Promise.reject(error);
            })
    return responseData;
}

/************************************************
 * verifyToken
 * @returns promise of token validation data 
 ************************************************/
export async function verifyToken(tokenVal) {
    const tokenValData = JSON.stringify(tokenVal);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: tokenValData 
    };
    const responseData = await fetch(constDefs.verifyTokenUrl, requestOptions) 
            .then(async response => {
                const data = await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    const error = (data && data.message) || response;
                    return error;
                }
                // Return data if no error
                return data;
            })
            .catch(error => {
                let errMsg = "ERROR: verifyToken: " + error;
                console.error(errMsg);
                return Promise.reject(error);
            })
    return responseData;
}