import { useState } from 'react'; 
import PropTypes from 'prop-types';

import {sendPhoneNumber, verifyOTP } from '../dataAccess/getData'; 
import * as constDefs from '../constants/constants';
import classes from './Login.module.css';

//Component
function Login( { setToken }) {
    const [error, setError] = useState('');
    const [isLoadingOTP, setIsLoadingOTP] = useState(false);
    const [showOTPContent, setShowOTPContent] = useState(false);
    const [showErrorPhoneContent, setShowErrorPhoneContent] = useState(false);
    const [showErrorOTPContent, setShowErrorOTPContent] = useState(false);
    const [inputValuePhone, setInputValuePhone] = useState('');
    const [inputValueOTP, setInputValueOTP] = useState('');
    const [isPhoneButtonDisabled, setIsPhoneButtonDisabled] = useState(true);
    const [isOTPButtonDisabled, setIsOTPButtonDisabled] = useState(true);
    const [validValuePhone, setValidValuePhone] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorCount, setErrorCount] = useState(0);

    //const navigate = useNavigate();

    const handlePhoneNumClick = () => {
        //console.log(inputValue);
        onSubmitPhoneNumHandler(inputValuePhone);
        //setInputValuePhone("");
        //setIsPhoneButtonDisabled(true) 
        setShowErrorPhoneContent(false);
        setShowErrorOTPContent(false);
    };

    const handleOTPClick = () => {
        //console.log(inputValue);
        onSubmitOTPHandler(inputValueOTP);
        setInputValueOTP("");
        setIsOTPButtonDisabled(true) 
        setShowErrorPhoneContent(false);
    };

    function onError(errMsg) {
        setError("ERROR Occurred: " + errMsg);
    }

    // Send Phone Number 
    function onSubmitPhoneNumHandler(phoneNumVal) {
        setIsLoadingOTP(true);
        let phoneNumberObj = {"phoneNumber": phoneNumVal};
        sendPhoneNumber(phoneNumberObj)
            .then((returnVal) => {
                    if (returnVal.statusCode == '200') {
                        if (returnVal.phoneNumberExists == 'yes') {
                            setValidValuePhone(phoneNumVal);
                            setShowOTPContent(true); 
                            setIsLoadingOTP(false);

                        } else {
                            setShowErrorPhoneContent(true);
                            setErrorMessage(returnVal.message);
                            setIsLoadingOTP(false);
                        }
                    } else {
                        setShowOTPContent(false);
                        setShowErrorPhoneContent(true);
                        setIsLoadingOTP(false);
                    }
                })
            .catch(e => {
                setShowOTPContent(false);
                setShowErrorPhoneContent(false);
                setShowErrorOTPContent(false);
                setIsLoadingOTP(false);
                let errMsg = "ERROR: sendPhoneNumber: " + e;
                onError(errMsg);
            });
    }

    // Send OTP 
    function onSubmitOTPHandler(otpVal) {
        let otpObj = {"phoneNumber": validValuePhone, "otp": otpVal};
        verifyOTP(otpObj)
            .then((returnVal) => {
                    if (returnVal.otpValid === 'yes') {
                    //if (returnVal.statusCode != '200') {
                        setShowOTPContent(false); 
                        setShowErrorPhoneContent(false);
                        // TODO: Redirect to the Availability page
                        //setCookie(constDefs.tokenKey, returnVal.token, constDefs.tokenExpireDays);
                        setToken(returnVal.token, constDefs.tokenKey, constDefs.tokenExpireDays);
                        //navigate('/availability');
                    } else {
                        setShowOTPContent(true);
                        setInputValueOTP("");
                        setShowErrorPhoneContent(false);
                        setShowErrorOTPContent(true);
                        setErrorCount(errorCount+1);
                    }
                })
            .catch(e => {
                setShowOTPContent(false);
                setShowErrorPhoneContent(false);
                setShowErrorOTPContent(false);
                let errMsg = "ERROR: sendOTP: " + e;
                onError(errMsg);
            });
    }

    function onSetPhoneChange(e) {
        const phoneNoPattern = /^\d{10}$/;
        if (e.match(phoneNoPattern) ) {
            setIsPhoneButtonDisabled(false) 
        } else {
            setIsPhoneButtonDisabled(true) 
        }
        setInputValuePhone(e);
        setErrorCount(0);
    }

    function onSetOTPChange(e) {
        if (e.length > 0) {
            setIsOTPButtonDisabled(false) 
        } else {
            setIsOTPButtonDisabled(true) 
        }
        setInputValueOTP(e);
    }

    let headerContent = (
        <div className={classes.headerLogin}>
            <p>Welcome to Tennis Time!</p>
        </div>
    )

    let phoneNumContent = (
        <div className={classes.phoneNumOTPLayout}>
            <label>To login, please enter your 10 digit phone number in the following format, then click Submit:</label>
            <div className={classes.examplePhone}>(Ex: 1112223333)</div>
            <div className={classes.phoneNumContent}>
                <input type="tel" className={classes.phoneNumInput} pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="1112223333" required value={inputValuePhone} onChange={(e) => onSetPhoneChange(e.target.value)}></input>
                <button type="button" className={isPhoneButtonDisabled ? classes.phoneNumButtonDisabled : classes.phoneNumButton} disabled={isPhoneButtonDisabled} id="phonenumbtn" name="phonenumbtn" onClick={handlePhoneNumClick}>Submit</button>
            </div>
        </div>
    );

    let otpContent = "";
    if (showOTPContent) {
        otpContent = (
            <div className={classes.otpLayout}>
                <label>Please enter your OTP sent via text message, then click Send OTP:</label>
                <div className={classes.otpContent}>
                    <input type="text" className={classes.otpInput} autoComplete="one-time-code" value={inputValueOTP} onChange={(e) => onSetOTPChange(e.target.value)}></input>
                    <button type="button" className={isOTPButtonDisabled ? classes.otpButtonDisabled : classes.otpButton} disabled={isOTPButtonDisabled} id="otptn" name="otpbtn" onClick={handleOTPClick}>Send OTP</button>
                </div>
            </div>
        )
    }

    let errorPhoneContent = (<div className={classes.errorPhoneLayout}></div>);
    if (showErrorPhoneContent) {
        errorPhoneContent = (
            <div className={classes.errorPhoneLayout}>
                <div className={classes.errorPhoneContent}>
                    **Error: {errorMessage}
                </div>
            </div>
        );
    }
        
    let errorOTPContent = (<div className={classes.errorOTPLayout}></div>);
    if (showErrorOTPContent) {
        errorOTPContent = (
            <div className={classes.errorOTPLayout}>
                <div className={classes.errorOTPContent}>
                    **Error: The OTP entered is not valid. Please re-enter OTP, or click Send New OTP.
                </div>
                <button type="button" className={classes.resendphoneNumButton} id="resendphonenumbtn" name="resendphonenumbtn" onClick={handlePhoneNumClick}>Send New OTP</button>
            </div>
        );
    }
        
    let loadingOTPContent = "";
    if (isLoadingOTP) {
        loadingOTPContent = (
            <div className={classes.loader}>
                <div className={classes.spinner}></div>
            </div>
        ) 
    }

    let content = "";

    // Set up content for rendering
    if (errorCount>3) {
        content = (
            <div className={classes.phoneNumOTPLayout}>
                <div className={classes.errorPhoneLayout}>
                    <div className={classes.errorPhoneContent}>
                        **Error: User exceeded number of attempts. Please contact TennisTime administrator.
                    </div>
                </div>
            </div>
        )
    }  else {
        content = (
            <div className={classes.phoneNumOTPLayout}>
                <div>
                    {errorPhoneContent}
                    {phoneNumContent}
                    {loadingOTPContent}
                    {otpContent}
                    {errorOTPContent}
                </div>
            </div>
        )
    }

    if (error) {
        content = (
            <div className={classes.phoneNumOTPLayout}>
                <div>
                    {error}
                </div>
            </div>
        )
    }

    return (
        <div>
            {headerContent}
            {content}
        </div>

    ); 
}

export default Login;

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
