import { NavLink } from "react-router-dom";

import * as constDefs from '../../constants/constants'; 
import classes from './MainNavigation.module.css';

function MainNavigation() {

    let userName = sessionStorage.getItem(constDefs.authNameKey);

    return(
        <div className={classes.header}>
            <div className={classes.headerName}>Hello {userName}</div>
            <nav>
                <ul className={classes.links}>
                    <li>
                        <NavLink to="/availability" className={({ isActive}) => isActive ? classes.active : undefined } end
                        >
                            Availability
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/lineup" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Lineup 
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/lineupadmin" className={({ isActive }) => isActive ? classes.active: undefined }
                        >
                            Admin 
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default MainNavigation;