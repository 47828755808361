import classes from './MainHeader.module.css';

function MainHeader(props) {
    return (
        <div>
            <div className={classes.header}>
                <div className={classes.headerTitle}>John, Mac & Co</div>
            </div>
        </div>
    );
}

export default MainHeader;
//            <h1>Tennis Time</h1>