import { useState } from 'react';
import * as constDefs from './constants/constants';

export default function useToken() {

    const getToken = () => {
        const cookies = document.cookie.split("; ").find((row) => row.startsWith(`${constDefs.tokenKey}=`));
        return cookies ? cookies.split("=")[1] : null;
    };

    const [token, setToken] = useState(getToken(constDefs.tokenKey));

    const saveToken = userToken => {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + constDefs.tokenExpireDays*24*60*60*1000);
      document.cookie = `${constDefs.tokenKey}=${userToken}; expires=${expirationDate.toUTCString()}; path=/`;
      setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }

}